import React, { useEffect, useState } from 'react';
import logo from './images/logo2.png'
import { PopupButton } from '@typeform/embed-react'

function Navbar() {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [isSmallScreen, setIsSmalScreen] = useState(false);

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  }

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 1024px)");
    mediaQuery.addEventListener("change", handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = mediaQuery => {
    if (mediaQuery.matches) {
      setIsSmalScreen(true);
    } else {
      setIsSmalScreen(false);
    }
  };

  return (
    <div className="App">
      <nav className="navbar flex items-center justify-between flex-wrap p-6">

        <div className='mobile-header flex flex-row items-center justify-between lg:justify-start"'>
          <a href='/'>
            <img class="logo" src={logo} alt="logo" width={"300px"} />
          </a>
        </div>

      </nav>
    </div>
  );
}

export default Navbar;