import './Swiper.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

import pic1 from './images/swipe4.png'
import pic2 from './images/swipe3.png'
import pic3 from './images/swipe2.png'
import pic4 from './images/swipe1.png'

import React from 'react'

import 'swiper/css';

function OverMij() {
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={0}
      breakpoints={{
        600: { slidesPerView: 2 }
      }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      modules={[Autoplay]}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
      <SwiperSlide><img className='' src={pic1} alt=""></img></SwiperSlide>
      <SwiperSlide><img className='' src={pic2} alt=""></img></SwiperSlide>
      <SwiperSlide><img className='' src={pic3} alt=""></img></SwiperSlide>
      <SwiperSlide><img className='' src={pic4} alt=""></img></SwiperSlide>
    </Swiper>
  )
}

export default OverMij