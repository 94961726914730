import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Coaching from './Services';
import OverMij from './Swiper';
import Testemonial from './OurStory';
import Diensten from './HowDoesItWork';
import Contact from './Contact';
import Footer from './Footer';
import FAQ from './FAQ';
import AppHome from './AppHome';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppHome />
  </React.StrictMode>
);