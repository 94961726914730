
import './FAQ.css';
import React from 'react'
import Accordion from './components/Accordion';


function FAQ() {
  return (
    <div className="p-8">
      <div className='text-center pb-4 font-bold '>
        <p className='DienstTitel font2 text-3xl lg:text-6xl text-center font-bold '>FAQ</p>

      </div>
      <Accordion
        title="1. Hoe werkt het custom made bestelproces?"
        content="U kunt uw custom made sieraad bestellen door op de 'Vraag een offerte aan' knop te drukken op onze website. Hier kunt u een afbeelding uploaden van het ontwerp dat u in gedachten heeft en de specificaties van het sieraad invullen. Ons team zal vervolgens uw aanvraag beoordelen en contact met u opnemen om verdere details te bespreken."
      />
      <Accordion
        title="2. Welk type goud gebruiken jullie voor de sieraden?"
        content="Wij bieden verschillende opties aan, waaronder 14k, 18k, 22k en 24k goud. Tijdens het bestelproces kunt u uw voorkeur aangeven."
      />
      <Accordion
        title="3. Hoe lang duurt het voordat mijn sieraad klaar is?"
        content="De productietijd varieert afhankelijk van de complexiteit van het ontwerp. Over het algemeen kunt u verwachten dat uw custom made sieraad binnen 3-4 weken klaar is. We zullen echter altijd een geschatte leverdatum met u communiceren zodra we uw bestelling hebben beoordeeld."
      />
      <Accordion
        title="4. Wat zijn de verzendkosten?"
        content="De verzendkosten zijn afhankelijk van uw locatie en de gewenste verzendmethode. Deze kosten worden duidelijk aangegeven tijdens het afrekenproces."
      />
      <Accordion
        title="5. Wat als ik niet tevreden ben met mijn sieraad?"
        content="Klanttevredenheid staat bij ons hoog in het vaandel. Als u om welke reden dan ook niet tevreden bent met uw bestelling, neem dan binnen 14 dagen na ontvangst contact met ons op, zodat we een passende oplossing kunnen vinden."
      />
      <Accordion
        title="6. Hoe kan ik voor mijn bestelling betalen?"
        content="Wij bieden verschillende veilige betaalmethoden aan. Alle betalingsopties worden weergegeven tijdens het afrekenproces."
      />
      <Accordion
        title="7. Hoe verzorg ik mijn sieraad het beste?"
        content="Goud is een duurzaam metaal, maar het is goed om voorzichtig te zijn om krassen en beschadigingen te voorkomen. We raden aan uw sieraad af te doen tijdens activiteiten zoals sporten, douchen of zwemmen. Bewaar uw sieraad in het meegeleverde doosje om het te beschermen."
      />
    </div>
  )
}

export default FAQ