
import './HowDoesItWork.css';
import React from 'react'
import { PopupButton } from '@typeform/embed-react'


function Diensten() {
  return (
    <div id="diensten" className='Diensten flex flex-col items-center justify-center'>
      <div className='p-8 flex flex-col justify-center items-center'>

        <div className='text-center pb-4 font-bold '>
          <p className='DienstTitel font2 text-3xl lg:text-6xl text-center font-bold '>Hoe werkt het?</p>

        </div>

        <div className='CardSection'>

          <div className='CardMasonry'>

            <div className='rounded-xl lg:p-8'>
              <div className='flex flex-col gap-3 pb-4 mt-6 mb-6'>
                <p className='text-xl text-center font-bold pb-4 border-b-2 '>1. Ontwerp Delen</p>
                <p className='text-xl text-justify'>Upload een afbeelding van uw gewenste sieraadontwerp en geef uw specificaties door.</p>
              </div>
            </div>

            <div className='rounded-xl lg:p-8'>
              <div className='flex flex-col gap-3 pb-4 mt-6 mb-6'>
                <p className='text-xl text-center font-bold pb-4 border-b-2  '>2. Ambachtelijke Creatie</p>
                <p className='text-xl text-justify'>Ons team van vakmensen beoordeelt uw ontwerp en gaat aan de slag met de vervaardiging van uw unieke gouden sieraad.</p>
              </div>
            </div>

            <div className='rounded-xl lg:p-8'>
              <div className='flex flex-col gap-3 pb-4 mt-6 mb-6'>
                <p className='text-xl text-center font-bold pb-4 border-b-2 '>3. Ontvang & Bewonder</p>
                <p className='text-xl text-justify'>Na zorgvuldige productie wordt uw op maat gemaakte sieraad veilig naar u verzonden. Nu is het tijd om te genieten van de schoonheid en de verfijnde details van uw persoonlijke meesterwerk.</p>
              </div>
            </div>

          </div>
        </div>

        <div>
          <PopupButton id="CWv3ldt1" style={{ fontSize: 20 }} className="border-2 rounded border-yellow-400 hover:bg-yellow-400  font-semibold hover:text-gray-800 py-2 px-4 hover:border-transparent mt-3">
            Vraag een offerte aan
          </PopupButton>
        </div>
      </div>

    </div>
  )
}

export default Diensten