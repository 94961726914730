import './OurStory.css';
import React from 'react'
import ring from './images/goldbars.png';

function testemonial() {
  return (
    <div id="resultaten" className='Resultaten picBack2  flex flex-row items-center justify-center flexwrapcss'>
      
      <div className='half2'>
        <img src={ring} alt='' />
      </div>

      <div className='half2'>
        <div className='lg:p-16'>
          <div className='p-8'>
            <div className='Testtitel font2 pb-2 text-center text-3xl lg:text-6xl font-bold'>
              <p>Our Story</p>
            </div>
            <div className='Testtitel text-xl  text-center pb-4 border-b-2 font-bold'>
              <p>The Journey Behind the Brand.</p>
            </div>
            <div className='flex flex-row mt-4 flex-wrap justify-center items-center gap-6'>
              <div className='ResultaatText reveal text-justify text-black  text-2xl'>
                Ons verhaal begon in de sportschool, waar een gedeelde passie voor uitmuntendheid en zelfverbetering ons samenbracht. In onze gesprekken ontdekten we een wederzijdse visie: een overtuiging dat in ieder van ons een 'gouden persoon' schuilt, iemand met een hart van goud.
                <br></br>
                <br></br>
                Uit deze visie werd "Altin Insan" geboren. Wij geloven dat iedereen een uniek verhaal heeft, en ons doel is om deze persoonlijke verhalen te vieren en te accentueren door middel van onze sieraden. "Altin Insan" staat voor de schoonheid en kracht van individualiteit, een eerbetoon aan ieders unieke levensreis.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default testemonial