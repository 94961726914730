import React from 'react'
import { BrowserRouter as Router, Routes, Route }
    from 'react-router-dom';
import Home from './Home';
import TOC from './TOC';
import PrivacyPolicy from './PrivacyPolicy';

function AppHome() {
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/TOC' element={<TOC />} />
          <Route path='/PrivacyPolicy' element={<PrivacyPolicy />} />
        </Routes>
      </Router>
    </div>
  );
}

export default AppHome;