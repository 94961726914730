import React from 'react'

import Navbar from './Navbar';
import Footer from './Footer';

function TOC() {
  return (
    <div>
      <Navbar />

      <div className='half'>
        <div className='lg:p-16'>
          <div className='p-8'>
            <div className='Testtitel font2 pb-2 border-b-2 text-3xl lg:text-3xl font-bold'>
              <p>Algemene Voorwaarden</p>
            </div>

            <div className='flex flex-row mt-8 align-te flex-wrap justify-center items-center gap-6 '>

              <div className='Image1text text-justify flex flex-col text-2xl '>
                <div>
                  <h2 className='font-bold'>1. Inleiding</h2>
                  <p>Deze algemene voorwaarden zijn van toepassing op alle transacties en interacties via de website van Altin Insan.</p>
                  <br></br>
                  <h2 className='font-bold'>2. Producten en Diensten</h2>
                  <p>Wij bieden custom made gouden sieraden aan, waarvan de specificaties door de klant worden aangeleverd. De getoonde afbeeldingen zijn representatief voor de verwachte producten.</p>
                  <br></br>
                  <h2 className='font-bold'>3. Bestelproces</h2>
                  <p>Bestellingen worden geplaatst via de website door een ontwerp te uploaden en de benodigde specificaties op te geven. Wij behouden ons het recht voor om bestellingen te weigeren die niet haalbaar zijn of buiten ons ethisch beleid vallen.</p>
                  <br></br>
                  <h2 className='font-bold'>4. Prijzen en Betaling</h2>
                  <p>Alle prijzen zijn in euro en zijn inclusief BTW. Betaling geschiedt via de aangegeven veilige betalingsmethodes op de website.</p>
                  <br></br>
                  <h2 className='font-bold'>5. Levering en Verzendbeleid</h2>
                  <p>De geschatte levertijd wordt gecommuniceerd bij de bevestiging van de bestelling. Altin Insan is niet aansprakelijk voor vertragingen veroorzaakt door verzendpartners of onvoorziene omstandigheden.</p>
                  <br></br>
                  <h2 className='font-bold'>6. Retourbeleid en Terugbetaling</h2>
                  <p>Custom made producten kunnen niet geretourneerd worden, tenzij er sprake is van een defect of afwijking van de bestelde specificaties. Eventuele klachten moeten binnen 14 dagen na ontvangst worden gemeld.</p>
                  <br></br>
                  <h2 className='font-bold'>7. Auteursrecht en Eigendom</h2>
                  <p>De inhoud van de website, waaronder tekst, afbeeldingen en ontwerpen, is eigendom van Altin Insan en mag niet zonder toestemming worden gebruikt.</p>
                  <br></br>
                  <h2 className='font-bold'>8. Privacybeleid</h2>
                  <p>Persoonlijke gegevens worden verwerkt en beschermd zoals beschreven in ons privacybeleid.</p>
                  <br></br>
                  <h2 className='font-bold'>9. Beperking van Aansprakelijkheid</h2>
                  <p>Altin Insan is niet aansprakelijk voor indirecte schade of gevolgschade die voortvloeit uit het gebruik van onze producten of diensten.</p>
                  <br></br>
                  <h2 className='font-bold'>10. Wijzigingen in Algemene Voorwaarden</h2>
                  <p>Altin Insan behoudt zich het recht voor om deze algemene voorwaarden op elk moment te wijzigen.</p>
                  <br></br>
                  <h2 className='font-bold'>11. Toepasselijk Recht en Jurisdictie</h2>
                  <p>Op deze voorwaarden is de Nederlandse recht van toepassing. Geschillen worden voorgelegd aan de bevoegde rechter in [locatie].</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default TOC;