import React, { useState } from 'react';

function Accordion({ title, content }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="w-full max-w-md mx-auto mt-4">
            <div 
                className="cursor-pointer border-b-2 text-center p-4 " 
                onClick={() => setIsOpen(!isOpen)}
            >
                <h3 className=" font-semibold">{title}</h3>
            </div>
            {isOpen && (
                <div className="mt-2 text-xl text-justify bg-white p-4">
                    <p>{content}</p>
                </div>
            )}
        </div>
    );
}

export default Accordion;