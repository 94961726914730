import './Footer.css';
import React from 'react'
import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faTiktok } from '@fortawesome/free-brands-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'

function Footer() {
  return (
    <div className='Footer text-center p-12 text-black '>
      <div className='flex flex-row gap-6 justify-center items-center p-2'>
        <a href='https://www.instagram.com/altininsanjewelry/'>
          <FontAwesomeIcon className='fa-2x' icon={faInstagram} />
        </a>
        <a href='https://www.tiktok.com/@altin.insan?_t=8hFiLqiNMrh&_r=1'>
          <FontAwesomeIcon className='fa-2x' icon={faTiktok} />
        </a>
        {/* <a href='https://www.instagram.com/altininsanjewelry/'>
          <FontAwesomeIcon className='fa-2x' icon={faFacebook} />
        </a> */}
      </div>
      <div className='flex flex-row gap-6 justify-center items-center p-4'>
        <Link to='/TOC' activeStyle>
          <p>Algemene Voorwaarden</p>
        </Link>
        <Link to='/PrivacyPolicy' activeStyle>
          <p>Privacy Policy</p>
        </Link>
      </div>
      <div>
        <p>Altin Insan 2023, <span>Alle Rechten voorbehouden</span></p>
      </div>

    </div>
  )
}

export default Footer