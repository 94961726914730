import React from 'react'
import { BrowserRouter as Router, Routes, Route }
    from 'react-router-dom';
import App from './App';
import Coaching from './Services';
import OverMij from './Swiper';
import Testemonial from './OurStory';
import Diensten from './HowDoesItWork';
import Contact from './Contact';
import Footer from './Footer';
import FAQ from './FAQ';

function Home() {
  return (
    <div>
      <App />
      <Coaching />
      <Testemonial />
      <OverMij />
      <Diensten />
      <Contact />
      <FAQ />
      <Footer />
    </div>
  );
}

export default Home;