import React from 'react'

import Navbar from './Navbar';
import Footer from './Footer';

function PrivacyPolicy() {
  return (
    <div>
      <Navbar />

      <div className='half'>
        <div className='lg:p-16'>
          <div className='p-8'>
            <div className='Testtitel font2 pb-2 border-b-2 text-3xl lg:text-3xl font-bold'>
              <p>Algemene Voorwaarden</p>
            </div>

            <div className='flex flex-row mt-8 align-te flex-wrap justify-center items-center gap-6 '>

              <div className='Image1text text-justify flex flex-col text-2xl '>
                <div>
                  <p>Datum van ingang: 11-11-2023</p>
                  <br></br>
                  <h2 className='font-bold'>1. Overzicht</h2>
                  <p>Dit privacybeleid beschrijft hoe Altin Insan (‘wij’, ‘ons’, ‘onze’) uw persoonlijke gegevens verzamelt, gebruikt en deelt wanneer u onze website www.altininsan.com bezoekt of een aankoop doet.</p>
                  <br></br>
                  <h2 className='font-bold'>2. Persoonlijke Informatie die Wij Verzamelen</h2>
                  <p>Wanneer u onze site bezoekt, verzamelen wij automatisch bepaalde informatie over uw apparaat, inclusief informatie over uw webbrowser, IP-adres, tijdzone en enkele van de cookies die op uw apparaat zijn geïnstalleerd. Daarnaast verzamelen we informatie over de individuele webpagina's of producten die u bekijkt, welke websites of zoektermen u naar onze site hebben verwezen en informatie over hoe u met onze site omgaat.</p>
                  <br></br>
                  <h2 className='font-bold'>3. Hoe Gebruiken We Uw Persoonlijke Informatie?</h2>
                  <p>Wij gebruiken de verzamelde informatie om aanvragen voor onze producten en diensten te verwerken en om te communiceren over bestellingen, producten, diensten en promotionele doeleinden. Daarnaast gebruiken we deze informatie om:</p>
                  <ul>
                    <li>Onze website te optimaliseren en te verbeteren;</li>
                    <li>Advertenties en marketingcampagnes te personaliseren;</li>
                    <li>Fraude te voorkomen en beveiliging te verhogen.</li>
                  </ul>
                  <br></br>
                  <h2 className='font-bold'>4. Delen van Persoonlijke Informatie</h2>
                  <p>Wij delen uw persoonlijke informatie met derde partijen om ons te helpen bij het gebruik van uw persoonlijke informatie, zoals hierboven beschreven. We gebruiken bijvoorbeeld [Geef een voorbeeld van een serviceprovider] om onze online winkel te beheren en u te kunnen bedienen.</p>
                  <br></br>
                  <h2 className='font-bold'>5. Uw Rechten</h2>
                  <p>Als u inwoner bent van Europa, heeft u het recht om toegang te vragen tot de persoonlijke informatie die we over u bewaren en om te vragen dat uw persoonlijke informatie wordt gecorrigeerd, bijgewerkt of verwijderd. Als u van dit recht gebruik wilt maken, neem dan contact met ons op via de onderstaande contactgegevens.</p>
                  <br></br>
                  <h2 className='font-bold'>6. Wijzigingen</h2>
                  <p>We kunnen dit privacybeleid van tijd tot tijd bijwerken om bijvoorbeeld veranderingen in onze praktijken of om andere operationele, wettelijke of regelgevende redenen weer te geven.</p>
                  <br></br>
                  <h2 className='font-bold'>Contacteer Ons</h2>
                  <p>Voor meer informatie over onze privacypraktijken, als u vragen hebt, of als u een klacht wilt indienen, neem dan contact met ons op via e-mail op contact.nl@altininsan.eu.
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default PrivacyPolicy;