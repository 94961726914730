import './Services.css';
import React from 'react'
import bracelet from './images/goldcoins.png';

function Coaching() {
  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }

  window.addEventListener("scroll", reveal);

  return (
    <div id="coaching" className='Coaching picBack flex flex-row items-center justify-between flexwrapcss2'>

      <div className='half'>
        <div className='lg:p-16'>
          <div className='p-8'>
            <div className='Testtitel font2 pb-2 text-center text-3xl lg:text-6xl font-bold'>
              <p>Services</p>
            </div>
            <div className='Testtitel text-xl  text-center pb-4 border-b-2 font-bold'>
              <p>Where Quality Meets Expertise.</p>
            </div>
            <div className='flex flex-row mt-8 align-te flex-wrap justify-center items-center gap-6 '>

              <div className='Image1text reveal text-justify flex flex-col text-2xl '>
                "Altin Insan" staat voor de pracht en praal van persoonlijke expressie. Wij begrijpen dat elk individu een uniek verhaal heeft en onze missie is om dat verhaal tot leven te brengen in de vorm van exquise gouden sieraden. Onze specialiteit ligt in het op maat maken van gouden sieraden, perfect vormgegeven naar uw wens en visie.
                <br></br>
                <br></br>
                Met een eenvoudig online proces kunt u uw droomontwerp met ons delen. U uploadt een afbeelding, geeft uw specificaties aan en ons toegewijde team van ambachtslieden gaat aan de slag om uw visie te realiseren. Van tijdloze stukken tot hedendaagse kunstwerken, bij "Altin Insan" is elk sieraad een viering van persoonlijkheid, passie en precisie. <br></br><br></br>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='half'>
        <img src={bracelet} alt='' />
      </div>
    </div>
  )
}

export default Coaching