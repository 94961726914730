import './Contact.css';
import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

function Contact() {

  const [status, setStatus] = useState("Submit");
  const [button, setButton] = useState(true);
  const [message, setMessage] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setStatus("Verzonden");
    setButton(false);
    setMessage(true);

    emailjs.sendForm('service_yqyuwe5', 'template_ypg8ore', form.current, 'user_Oi0lKtYK3i025ldanfhB6')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <div id="contact" className='Contact flex flex-row items-center justify-center '>
      <div className='half'>
        <div className='p-16'>
          <div className=''>
            <div className='text-center pb-4 text-3xl lg:text-6xl font2  font-bold'>
              <p>Contact</p>
            </div>

            <div className='flex justify-center'>
              <form ref={form} onSubmit={sendEmail} className='bg-contact  m-2'>
                <div className=''>
                    <div className='flex flex-row flex-wrap gap-3 pb-3'>
                      <input className='border rounded  w-full py-2 px-3 text-black leading-tight focus:outline-none' type="text" id="name" name='name' placeholder='Naam'></input>
                      <input className='border rounded w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none' type="text" id="email" name='email' placeholder='Email'></input>
                  </div>


                  <div className='flex flex-row pb-3'>
                    <textarea className='border rounded w-full py-2 px-3  leading-tight focus:outline-none' type="text" id="message" name='message' rows="4" placeholder='Bericht'></textarea>
                  </div>

                  {(button) && (
                    <div className='border-t-2 italic font-semibold'>
                      <button className='border-2 rounded border-yellow-400 hover:bg-yellow-400 font-semibold hover:text-gray-800 py-2 px-4 hover:border-transparent mt-3'>
                        Verstuur
                      </button>
                    </div>
                  )}

                  {(message) && (
                    <div className='font-bold text-white'>
                      Uw Bericht is verzonden!
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>

        </div>
      </div>

      

    </div>
  )
}

export default Contact