import './App.css';
import logo from './images/logo2.png'
import React, { useEffect, useState } from 'react';
import { PopupButton } from '@typeform/embed-react'
import videoBG from './videos/introvideo2.mp4'

function App() {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [isSmallScreen, setIsSmalScreen] = useState(false);

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  }

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 1024px)");
    mediaQuery.addEventListener("change", handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = mediaQuery => {
    if (mediaQuery.matches) {
      setIsSmalScreen(true);
    } else {
      setIsSmalScreen(false);
    }
  };

  return (
    <div className="App">
      <video src={videoBG} loop="true" muted="true" autoPlay="autoplay" playsInLine="playsinline" type="video/mp4"/>
      <header className="App-header">

        <nav className="navbar flex items-center justify-between flex-wrap p-6">

          <div className='mobile-header flex flex-row items-center justify-between lg:justify-start"'>
            <div>
              <img class="logo" src={logo} alt="logo" width={"300px"} />
            </div>
            <div className="block lg:hidden">
              <button onClick={toggleNav} className="flex items-center px-3 py-2 border rounded text-white border-white hover:text-white hover:border-white">
                <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
              </button>
            </div>
          </div>


          <div className="w-full block flex-grow lg:flex lg:items-center lg:justify-end lg:w-auto">
            {(!isSmallScreen || isNavVisible) && (
              <div className="text-xl absolute lg:flex-grow">
                <a href="#coaching" className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-yellow-400 mr-4">Services</a>
                <a href="#resultaten" className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-yellow-400 mr-4">FAQs</a>
                <a href="#diensten" className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-yellow-400 mr-4">Our Story</a>
                <a href="#contact" className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-yellow-400 mr-4">Contact</a>
                <PopupButton id="CWv3ldt1" style={{ fontSize: 20 }} className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-yellow-400 mr-4">
                  Vraag een offerte aan
                </PopupButton>
              </div>
            )}
          </div>
        </nav>

        <div className='title md:ml-6 flex flex-col items-center justify-end md:justify-end md:items-start text-white'>
          <div>
            <p className='p1 font2 text-3xl lg:text-4xl'>GOLD,</p>
          </div>
          <div>
            <p className='p1 font2 text-center text-3xl lg:text-4xl'>CRAFTED FOR YOU</p>
          </div>
          <div className='HeaderText text-white text-2xl'>
            <p>Custom made golden jewelry
            </p>
          </div>

        </div>

      </header>
    </div>
  );
}

export default App;
